const SIGN_IN_STEPS = [
  // Amplify/authのnextStep文字列
  'NONE',
  'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
  'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
  'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  'CONFIRM_SIGN_IN_WITH_SMS_CODE',
  'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
  'CONFIRM_SIGN_IN_WITH_EMAIL_CODE',
  'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
  'CONTINUE_SIGN_IN_WITH_EMAIL_SETUP',
  'CONTINUE_SIGN_IN_WITH_MFA_SETUP_SELECTION',
  'CONTINUE_SIGN_IN_WITH_FIRST_FACTOR_SELECTION',
  'CONFIRM_SIGN_IN_WITH_PASSWORD',
  'CONFIRM_SIGN_UP',
  'FORGOT_PASSWORD', // Asukumiの画面遷移用に追加
  'CONFIRM_RESET_PASSWORD_WITH_CODE',
  'RESET_PASSWORD',
  'DONE',
] as const;

export type AuthSignInStep = (typeof SIGN_IN_STEPS)[number];

export function isAuthSignInStep(value: string): value is AuthSignInStep {
  return SIGN_IN_STEPS.includes(value as AuthSignInStep);
}

const LOWERCASE = '(?=.*?[a-z])';
const UPPERCASE = '(?=.*?[A-Z])';
const NUMBERS = '(?=.*?\\d)';
const SYMBOLS = '(?=.*?[_\\W])';
export const POLICY_REGEX = new RegExp('^(' + LOWERCASE + UPPERCASE + NUMBERS + SYMBOLS + ').*$');
